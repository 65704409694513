<template>
  <div>
    <Header :title="getShop().organisation.name ? getShop().organisation.name : null" :lead="null" :header="getShop().theme.header_image ? `https://api.tixgo.nl/files/` + getShop().theme.header_image : null" />
    <b-container fluid class="header-pull">
      <b-card no-body class="mb-3">
        <b-card-header>
          <h4 class="fs-4 mb-0">Programma</h4>
        </b-card-header>
        <b-card-body>
          <Schedule target="_self" :path="'/' + $route.params.host + '/event/'" />
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>
  
<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'

  import Header from '@/shared/components/Header'
  import Schedule from '@/shared/components/Schedule';
  
  export default {
    metaInfo: {
      title: 'Agenda',
    },
    components: {
      Header,
      Schedule,
    },
    data() {
        return {
          events: [],
        }
    },
    computed: {},
    methods: {
      ...mapActions(useShopStore, ['getShop']),
    },
    created() {}
  }
</script>